<template>
    <div>
        <el-dialog v-model="dialogVisible" width="840px" append-to-body :show-close="false">
            <template #title>
                <div class="dialog_box hide_print">
                    <div class="con_title"><span></span> 打印预览</div>
                </div>
            </template>
            <div class="font_div" v-if="!loading" :class="'size_id' + size_id" :style="'font-family:' + (font || 'inherit')">
                <div class="hide_print" v-if="cur_model == 4 || cur_model == 6">
                    <el-radio-group v-model="size_id">
                        <el-radio :label="item.id" v-for="(item, index) in size" :key="index">{{ item.name }}</el-radio>
                    </el-radio-group>
                    <!-- <el-radio-group v-model="size_id">
                  <el-radio :label="item.id" v-for="(item,index) in size" :key="index">{{item.name}}</el-radio>
                </el-radio-group> -->
                </div>
                <div class="hide_print">
                    <el-select v-model="font" placeholder="请选择字体" style="width: 300px;">
                        <el-option label="自定义" value=""></el-option>
                        <el-option :label="item + '(示例：1234567890)'" v-for="(item, index) in fontlist" :key="index"
                            :value="item" :style="'font-family:' + (item || 'inherit')"></el-option>
                    </el-select>
                    <el-input v-model="font" maxlength="20" placeholder="请输入自定义字体"
                        style="width:200px; margin-left: 10px;" />
                </div>

                <!-- 采购计划单 版式一 -->
                <template v-if="cur_model == 1">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">采购计划单</div>
                        <div class="flex_ac" style="margin-top: -50px;">
                            <div class="flex1">采购日期：{{ item3.purchase_plan_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.purchase_plan_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">采购单位：{{ item3.merchant.merchant_name }}</div>
                            <div class="flex_s right_tt">采购员：{{ item3.admin_name }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">收货单位：{{ item3.supplier }}</div>
                            <div class="flex_s right_tt"></div>
                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>产品编号</td>
                                    <td width="23%">注册证名 产品名称 规格型号</td>
                                    <td width="100px">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <!-- <td><div>批号</div><div>生产日期</div></td>
                                <td>有效期至</td> -->
                                    <td width="100px">注册证号</td>
                                </tr>
                                <!-- <template v-for="(item2,index2) in 28"> -->
                                <tr v-for="(item, index) in item3.purchase_plan_product" :key="index">
                                    <td>{{ item.product.product_no }}</td>
                                    <td>{{ item.product.register_cert_name }} {{ item.product.product_name }}
                                        {{ item.product.model }}</td>
                                    <td>{{ item.product.manufacturer }}</td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space:nowrap;">{{ item.product_num }}</td>
                                    <td style="white-space:nowrap;">{{ cutZero(item.product_price) }}</td>
                                    <td style="white-space:nowrap;">
                                        {{ cutZero(item.amount) }}
                                    </td>
                                    <!-- <td><div>{{item.batch_number}}</div></td>
                                <td>{{item.valid_until || '长效期'}}</td> -->
                                    <td>{{ item.product.register_cert_no }}</td>
                                </tr>
                                <!-- </template> -->
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="4" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="2">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <div class="show_print">{{ item3.address_text || '收件地址：' }}</div>
                            <div class="hide_print">
                                收件地址：
                                <el-select v-model="item3.address" @change="change($event, index3)" placeholder="请输入收货人搜索"
                                    size="small" clearable style="width:300px" filterable>
                                    <el-option
                                        :label="item.title + '-' + item.mobile + '-' + item.province_name + item.city_name + item.district_name + item.street_name + item.detail"
                                        v-for="(item, index) in addresslist" :key="index" :value="item.id"></el-option>
                                </el-select>
                            </div>
                            <div>备注：{{ item3.remark }}</div>
                        </div>
                    </div>
                </template>


                <!-- 采购验收单板式一 -->
                <template v-if="cur_model == 2">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">采购验收单</div>
                        <div class="flex_ac" style="margin-top: -50px;">
                            <div class="flex1">采购日期：{{ item3.purchase_plan.purchase_plan_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.purchase_plan.purchase_plan_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">采购单位：{{ item3.purchase_plan.merchant.merchant_name }}</div>
                            <div class="flex_s right_tt">采购员：{{ item3.purchase_plan.purchase_plan_name }}</div>
                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>产品编号</td>
                                    <td width="23%">注册证名 产品名称 规格型号</td>
                                    <td width="100px">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <td>
                                        <div>批号</div>
                                        <div>生产日期</div>
                                    </td>
                                    <td>有效期至</td>
                                    <td width="100px">注册证号</td>
                                </tr>
                                <tr v-for="(item, index) in item3.purchase_acceptance_product" :key="index">
                                    <td>{{ item.purchase_plan_product.product.product_no }}</td>
                                    <td>{{ item.purchase_plan_product.product.register_cert_name }}
                                        {{ item.purchase_plan_product.product.product_name }}
                                        {{ item.purchase_plan_product.product.model }}</td>
                                    <td>{{ item.purchase_plan_product.product.manufacturer }}</td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space: nowrap">{{ item.product_num }}</td>
                                    <td style="white-space: nowrap">{{ cutZero(item.product_price) }}</td>
                                    <td style="white-space: nowrap">
                                        {{ cutZero((parseFloat(item.product_price) * parseFloat(item.product_num) ||
                                            0).toFixed(2)) }}
                                    </td>
                                    <td>
                                        <div>{{ item.batch_number }}</div>
                                    </td>
                                    <td>{{ item.valid_until || '长效期' }}</td>
                                    <td>{{ item.purchase_plan_product.product.register_cert_no }}</td>
                                </tr>
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="4" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="3">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <div>
                                <!-- 质管员：张三 审核 同意 业务经理：李四 审核 同意 保管员：王二 审核 同意 -->
                                {{ get_sh_data(index3) }}
                            </div>
                            <div>备注：{{ item3.remark }}</div>
                        </div>
                    </div>
                </template>


                <!-- 采购入库单板式一 -->
                <template v-if="cur_model == 3">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">采购入库单</div>
                        <div class="flex_ac" style="margin-top: -50px;">
                            <div class="flex1">采购日期：{{ item3.purchase_acceptance.purchase_plan.purchase_plan_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.purchase_storage_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">采购单位：{{ item3.purchase_acceptance.purchase_plan.merchant.merchant_name }}</div>
                            <div class="flex_s right_tt">采购员：{{ item3.purchase_acceptance.purchase_plan.purchase_plan_name }}
                            </div>
                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>产品编号</td>
                                    <td width="23%">注册证名 产品名称 规格型号</td>
                                    <td width="100px">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <td>
                                        <div>批号</div>
                                        <div>生产日期</div>
                                    </td>
                                    <td>有效期至</td>
                                    <td width="100px">注册证号</td>
                                </tr>
                                <tr v-for="(item, index) in item3.purchase_storage_product" :key="index">
                                    <td>{{ item.purchase_acceptance_product.purchase_plan_product.product.product_no }}</td>
                                    <td>{{ item.purchase_acceptance_product.purchase_plan_product.product.register_cert_name }}
                                        {{ item.purchase_acceptance_product.purchase_plan_product.product.product_name }}
                                        {{ item.purchase_acceptance_product.purchase_plan_product.product.model }}</td>
                                    <td>{{ item.purchase_acceptance_product.purchase_plan_product.product.manufacturer }}</td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space:nowrap;">{{ item.purchase_acceptance_product.product_num }}</td>
                                    <td style="white-space:nowrap;">{{ cutZero(item.purchase_acceptance_product.product_price) }}</td>
                                    <td style="white-space:nowrap;">
                                        {{ cutZero((parseFloat(item.purchase_acceptance_product.product_price) * parseFloat(item.purchase_acceptance_product.product_num)
                                            || 0).toFixed(2)) }}
                                    </td>
                                    <td>
                                        <div>{{ item.batch_number }}</div>
                                    </td>
                                    <td>{{ item.valid_until || '长效期' }}</td>
                                    <td>{{ item.purchase_acceptance_product.purchase_plan_product.product.register_cert_no }}
                                    </td>
                                </tr>
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="4" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="3">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <!-- <div  >
                            {{get_sh_data(index3)}}
                        </div> -->
                            <div>备注：{{ item3.remark }}</div>
                        </div>
                    </div>
                </template>
                <!-- 销售出库单链式 -->
                <template v-if="cur_model == 4 && size_id != 2">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">销售出库单</div>
                        <div class="flex_ac" style="margin-top: -50px;">
                            <div class="flex1">销售日期：{{ item3.sale_out_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.sale_out_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">供货单位：{{ item3.supplier }}</div>
                            <div class="flex_s right_tt">业务员：{{ item3.admin_name }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">收货单位：{{ item3.sale_plan.merchant.merchant_name }}</div>

                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>产品编号</td>
                                    <td width="23%">注册证名 产品名称 规格型号</td>
                                    <td style="width: 100px;">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <td>
                                        <div>批号</div>
                                        <div>生产日期</div>
                                    </td>
                                    <td>有效期至</td>
                                    <td width="100px">注册证号</td>
                                </tr>
                                <tr v-for="(item, index) in item3.sale_out_product" :key="index">
                                    <td>{{ item.productInfo.product_no }}</td>
                                    <td>{{ item.productInfo.product_name }}
                                    </td>
                                    <td>{{ item.productInfo.manufacturer }}</td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space:nowrap;">{{ item.product_num }}</td>
                                    <td style="white-space:nowrap;">{{ cutZero(item.sale_price) }}</td>
                                    <td style="white-space:nowrap;">
                                        {{ cutZero((parseFloat(item.sale_price) * parseFloat(item.product_num) ||
                                            0).toFixed(2)) }}
                                    </td>
                                    <td>
                                        <div>{{ item.batch_number }}</div>
                                    </td>
                                    <td>{{ item.valid_until || '长效期' }}</td>
                                    <td>{{ item.productInfo.register_cert_no }}</td>
                                </tr>
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="4" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="3">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <div class="flex_ac">
                                <div class="flex1">
                                    发货员：{{ item3.consignor }} <span class="pad_l10">复核员：{{ item3.reviewer }}</span> <span
                                        class="pad_l10">客户签收：</span>
                                </div>
                                <div class="flex_s" style="margin-left:10px; font-weight: bold;">收到货物后，请核对。如有问题请72小时内反馈。
                                </div>
                            </div>
                            <div>
                                <div class="show_print">{{ item3.address_text || '收件地址：' }}</div>
                                <div class="hide_print">
                                    收件地址：
                                    <el-select v-model="item3.address" @change="change($event, index3)"
                                        placeholder="请输入收货人搜索" size="small" clearable style="width:300px" filterable>
                                        <el-option
                                            :label="item.title + '-' + item.mobile + '-' + item.province_name + item.city_name + item.district_name + item.street_name + item.detail"
                                            v-for="(item, index) in addresslist" :key="index" :value="item.id"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div>备注：{{ item3.remark }}</div>
                        </div>
                    </div>
                </template>
                <!-- 销售出库单A4 -->
                <template v-if="cur_model == 4 && size_id == 2">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">销售出库单</div>
                        <div class="stitle" style="text-align: center;margin-top: -15px; font-size: 14px;">
                            {{ item3.supplier }}</div>
                        <div class="flex_ac" style="margin-top: -30px;">
                            <div class="flex1">购货日期：{{ item3.sale_out_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.sale_out_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">客户名称：{{ item3.sale_plan.merchant.merchant_name }}</div>
                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>注册证名称</td>
                                    <td width="23%">产品名称 规格 型号</td>
                                    <!-- <td>规格</td> -->
                                    <td width="100px">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <td>
                                        <div>批号</div>
                                        <div>生产日期</div>
                                    </td>
                                    <td>有效期至</td>
                                    <td width="100px">注册证号</td>
                                </tr>
                                <tr v-for="(item, index) in item3.sale_out_product" :key="index">
                                    <td>{{ item.productInfo.register_cert_name }}</td>
                                    <td>{{ item.productInfo.product_name }}
                                        {{ item.productInfo.model }}</td>
                                    <!-- <td>{{JSON.parse(item.sale_plan_product.product).model}}</td> -->
                                    <td>{{ item.productInfo.manufacturer }}</td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space:nowrap;">{{ item.product_num }}</td>
                                    <td style="white-space:nowrap;">{{ cutZero(item.sale_price) }}</td>
                                    <td style="white-space:nowrap;">
                                        {{ cutZero((parseFloat(item.sale_price) * parseFloat(item.product_num) ||
                                            0).toFixed(2)) }}
                                    </td>
                                    <td>
                                        <div>{{ item.batch_number }}</div>
                                    </td>
                                    <td>{{ item.valid_until || '长效期' }}</td>
                                    <td>{{ item.productInfo.register_cert_no }}</td>
                                </tr>
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="5" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="3">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <div class="flex_ac">
                                <div class="flex1 flex show_print_flex">
                                    <div class="show_print">{{ item3.address_text || '收件地址：' }}</div>
                                    <div class="hide_print">
                                        收件地址：
                                        <el-select v-model="item3.address" @change="change($event, index3)"
                                            placeholder="请输入收货人搜索" size="small" clearable style="width:200px" filterable>
                                            <el-option
                                                :label="item.title + '-' + item.mobile + '-' + item.province_name + item.city_name + item.district_name + item.street_name + item.detail"
                                                v-for="(item, index) in addresslist" :key="index"
                                                :value="item.id"></el-option>
                                        </el-select>
                                    </div>
                                    <span class="pad_l10">电话报单：027-87225067</span>
                                    <span class="pad_l10">售前售后：027-87225087</span>
                                    <span class="pad_l10">传真：027-87225090</span>
                                </div>
                            </div>
                            <div><span
                                    style="text-decoration: underline; margin-right: 10px;">收到货物后，请核对。如有问题请72小时内反馈。</span>发货员：<span
                                    style="padding-left: 10px; padding-right: 10px;">{{ item3.consignor }}</span> 复核员：<span
                                    style="padding-left: 10px;">{{ item3.reviewer }}</span></div>
                            <div>备注：{{ item3.remark }}</div>
                        </div>
                    </div>
                </template>


                <!-- 销售计划单 -->
                <template v-if="cur_model == 5">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">销售计划单</div>
                        <div class="flex_ac" style="margin-top: -50px;">
                            <div class="flex1">计划日期：{{ item3.sale_plan_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.sale_plan_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">供货单位：{{ item3.supplier }}</div>
                            <div class="flex_s right_tt">业务员：{{ item3.admin_name }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">客户单位：{{ item3.merchant.merchant_name }}</div>

                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>产品编号</td>
                                    <td width="23%">注册证名 产品名称 规格型号</td>
                                    <td width="100px">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <td>
                                        <div>批号</div>
                                        <div>生产日期</div>
                                    </td>
                                    <td>有效期至</td>
                                    <td width="100px">注册证号</td>
                                </tr>
                                <tr v-for="(item, index) in item3.sale_plan_product" :key="index">
                                    <td>{{ item.product.product_no }}</td>
                                    <td><!-- {{item.product.register_cert_name}} -->{{ item.product.product_name }}<!-- {{item.product.model}} -->
                                    </td>
                                    <td>{{ item.product.manufacturer }}</td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space:nowrap;">{{ item.product_num }}</td>
                                    <td style="white-space:nowrap;">{{ cutZero(item.sale_price) }}</td>
                                    <td style="white-space:nowrap;">
                                        {{ cutZero((parseFloat(item.sale_price) * parseFloat(item.product_num) ||
                                            0).toFixed(2)) }}
                                    </td>
                                    <td>
                                        <div>{{ item.batch_number }}</div>
                                    </td>
                                    <td>{{ item.valid_until || '长效期' }}</td>
                                    <td>{{ item.product.register_cert_no }}</td>
                                </tr>
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="4" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="3">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <div class="flex_ac">
                                <div class="flex1">
                                    <div class="show_print">{{ item3.address_text || '收件地址：' }}</div>
                                    <div class="hide_print">
                                        收件地址：
                                        <el-select v-model="item3.address" @change="change($event, index3)"
                                            placeholder="请输入收货人搜索" size="small" clearable style="width:300px" filterable>
                                            <el-option
                                                :label="item.title + '-' + item.mobile + '-' + item.province_name + item.city_name + item.district_name + item.street_name + item.detail"
                                                v-for="(item, index) in addresslist" :key="index"
                                                :value="item.id"></el-option>
                                        </el-select>
                                    </div>
                                    <!-- 发货员：{{data.consignor}} <span class="pad_l10">复核员：{{data.reviewer}}</span>  <span class="pad_l10">客户签收：</span> -->
                                </div>
                                <div class="flex_s" style="margin-left:10px; font-weight: bold;">收到货物后，请核对。如有问题请72小时内反馈。
                                </div>
                            </div>
                            <div>

                            </div>
                            <div>备注：{{ item3.remark }}</div>
                        </div>
                    </div>
                </template>


                <!-- 销售退回单 -->
                <template v-if="cur_model == 6 && size_id != 2">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">销售退回单</div>
                        <div class="flex_ac" style="margin-top: -50px;">
                            <div class="flex1">退回日期：{{ item3.sale_return_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.sale_return_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">供货单位：{{ item3.supplier }}</div>
                            <div class="flex_s right_tt">业务员：{{ item3.admin_name }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">客户单位：{{ item3.sale_out.sale_plan.merchant.merchant_name }}</div>
                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>产品编号</td>
                                    <td width="23%">注册证名 产品名称 规格型号</td>
                                    <td width="100px">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <td>
                                        <div>批号</div>
                                        <div>生产日期</div>
                                    </td>
                                    <td>有效期至</td>
                                    <td width="100px">注册证号</td>
                                </tr>
                                <tr v-for="(item, index) in item3.sale_return_product" :key="index">
                                    <td>{{ JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).product_no }}
                                    </td>
                                    <td><!-- {{JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).register_cert_name}} -->{{ JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).product_name }}<!-- {{JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).model}} -->
                                    </td>
                                    <td>{{ JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).manufacturer }}
                                    </td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space:nowrap;">{{ item.product_num }}</td>
                                    <td style="white-space:nowrap;">{{ cutZero(item.product_price) }}</td>
                                    <td style="white-space:nowrap;">
                                        {{ cutZero((parseFloat(item.product_price) * parseFloat(item.product_num) ||
                                            0).toFixed(2)) }}
                                    </td>
                                    <td>
                                        <div>{{ item.batch_number }}</div>
                                    </td>
                                    <td>{{ item.valid_until || '长效期' }}</td>
                                    <td>{{ JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).register_cert_no }}
                                    </td>
                                </tr>
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="4" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="3">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <div class="flex_ac">
                                <div class="flex1">
                                    发货员：{{ item3.consignor }} <span class="pad_l10">复核员：{{ item3.reviewer }}</span> <span
                                        class="pad_l10">客户签收：</span>
                                </div>
                                <div class="flex_s" style="margin-left:10px; font-weight: bold;">收到货物后，请核对。如有问题请72小时内反馈。
                                </div>
                            </div>
                            <div>
                                <div class="show_print">{{ item3.address_text || '收件地址：' }}</div>
                                <div class="hide_print">
                                    收件地址：
                                    <el-select v-model="item3.address" @change="change($event, index3)"
                                        placeholder="请输入收货人搜索" size="small" clearable style="width:300px" filterable>
                                        <el-option
                                            :label="item.title + '-' + item.mobile + '-' + item.province_name + item.city_name + item.district_name + item.street_name + item.detail"
                                            v-for="(item, index) in addresslist" :key="index" :value="item.id"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div>备注：{{ item3.remark }}</div>
                        </div>
                    </div>
                </template>
                <!-- 销售退回单样式二 -->
                <template v-if="cur_model == 6 && size_id == 2">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">销售退回单</div>
                        <div class="stitle" style="text-align: center;margin-top: -15px; font-size: 14px;">
                            {{ item3.supplier }}</div>
                        <div class="flex_ac" style="margin-top: -30px;">
                            <div class="flex1">退回日期：{{ item3.sale_return_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.sale_return_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">客户名称：{{ item3.sale_out.sale_plan.merchant.merchant_name }}</div>
                            <div class="flex_s right_tt">备注：{{ item3.remark }}</div>
                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>注册证名称</td>
                                    <td width="23%">产品名称 规格 型号</td>
                                    <!-- <td>规格</td> -->
                                    <td width="100px">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <td>
                                        <div>批号</div>
                                        <div>生产日期</div>
                                    </td>
                                    <td>有效期至</td>
                                    <td width="100px">注册证号</td>
                                </tr>
                                <tr v-for="(item, index) in item3.sale_return_product" :key="index">
                                    <td>{{ JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).register_cert_name }}
                                    </td>
                                    <td>{{ JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).product_name }}
                                        {{ JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).model }}
                                    </td>
                                    <!-- <td>{{JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).model}}</td> -->
                                    <td>{{ JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).manufacturer }}
                                    </td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space:nowrap;">{{ item.product_num }}</td>
                                    <td style="white-space:nowrap;">{{ cutZero(item.product_price) }}</td>
                                    <td style="white-space:nowrap;">
                                        {{ cutZero((parseFloat(item.product_price) * parseFloat(item.product_num) ||
                                            0).toFixed(2)) }}
                                    </td>
                                    <td>
                                        <div>{{ item.batch_number }}</div>
                                    </td>
                                    <td>{{ item.valid_until || '长效期' }}</td>
                                    <td>{{ JSON.parse(JSON.parse(item.sale_out_product.sale_plan_product).product).register_cert_no }}
                                    </td>
                                </tr>
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="5" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="3">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <div class="flex_ac">
                                <div class="flex1 flex show_print_flex">
                                    <div class="show_print">{{ item3.address_text || '收件地址：' }}</div>
                                    <div class="hide_print">
                                        收件地址：
                                        <el-select v-model="item3.address" @change="change($event, index3)"
                                            placeholder="请输入收货人搜索" size="small" clearable style="width:200px" filterable>
                                            <el-option
                                                :label="item.title + '-' + item.mobile + '-' + item.province_name + item.city_name + item.district_name + item.street_name + item.detail"
                                                v-for="(item, index) in addresslist" :key="index"
                                                :value="item.id"></el-option>
                                        </el-select>
                                    </div>
                                    <span class="pad_l10">电话报单：027-87225067</span>
                                    <span class="pad_l10">售前售后：027-87225087</span>
                                    <span class="pad_l10">传真：027-87225090</span>
                                </div>
                            </div>
                            <div><span
                                    style="text-decoration: underline; margin-right: 10px;">收到货物后，请核对。如有问题请72小时内反馈。</span>发货员：<span
                                    style="padding-left: 10px; padding-right: 10px;">{{ item3.consignor }}</span> 复核员：<span
                                    style="padding-left: 10px;">{{ item3.reviewer }}</span></div>
                        </div>
                    </div>
                </template>


                <!-- 采购退货单 -->
                <template v-if="cur_model == 7">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">采购退货单</div>
                        <div class="flex_ac" style="margin-top: -50px;">
                            <div class="flex1">退货日期：{{ item3.purchase_return_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.purchase_return_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">供货单位：{{ item3.supplier }}</div>
                            <div class="flex_s right_tt">保管员：{{ item3.keeper_name }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">采购单位：{{ item3.merchant_name }}</div>
                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>产品编号</td>
                                    <td width="23%">注册证名 产品名称 规格型号</td>
                                    <td width="100px">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>单价</td>
                                    <td>金额</td>
                                    <td>
                                        <div>批号</div>
                                        <div>生产日期</div>
                                    </td>
                                    <td>有效期至</td>
                                    <td>注册证号</td>
                                </tr>
                                <tr v-for="(item, index) in item3.purchase_return_product" :key="index">
                                    <td>{{ item.purchase_storage_product.purchase_acceptance_product.purchase_plan_product.product.product_no }}
                                    </td>
                                    <td>{{ item.purchase_storage_product.purchase_acceptance_product.purchase_plan_product.product.register_cert_name }}
                                        {{ item.purchase_storage_product.purchase_acceptance_product.purchase_plan_product.product.product_name }}
                                        {{ item.purchase_storage_product.purchase_acceptance_product.purchase_plan_product.product.model }}
                                    </td>
                                    <td>{{ item.purchase_storage_product.purchase_acceptance_product.purchase_plan_product.product.manufacturer }}
                                    </td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space:nowrap;">{{ item.product_num }}</td>
                                    <td style="white-space:nowrap;">{{ cutZero(item.product_price) }}</td>
                                    <td style="white-space:nowrap;">
                                        {{ cutZero((parseFloat(item.product_price) * parseFloat(item.product_num) ||
                                            0).toFixed(2)) }}
                                    </td>
                                    <td>
                                        <div>{{ item.batch_number }}</div>
                                    </td>
                                    <td>{{ item.valid_until || '长效期' }}</td>
                                    <td>{{ item.purchase_storage_product.purchase_acceptance_product.purchase_plan_product.product.register_cert_no }}
                                    </td>
                                </tr>
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="4" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="3">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <div class="flex_ac">
                                <div class="flex1">
                                    发货员：{{ item3.consignor }} <span class="pad_l10">复核员：{{ item3.reviewer }}</span> <span
                                        class="pad_l10">客户签收：</span>
                                </div>
                                <div class="flex_s" style="margin-left:10px; font-weight: bold;">收到货物后，请核对。如有问题请72小时内反馈。
                                </div>
                            </div>
                            <div>
                                <div class="show_print">{{ item3.address_text || '收件地址：' }}</div>
                                <div class="hide_print">
                                    收件地址：
                                    <el-select v-model="item3.address" @change="change($event, index3)"
                                        placeholder="请输入收货人搜索" size="small" clearable style="width:300px" filterable>
                                        <el-option
                                            :label="item.title + '-' + item.mobile + '-' + item.province_name + item.city_name + item.district_name + item.street_name + item.detail"
                                            v-for="(item, index) in addresslist" :key="index" :value="item.id"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div>备注：{{ item3.remark }}</div>
                        </div>
                    </div>
                </template>
                <!-- 报价单 -->
                <template v-if="cur_model == 8">
                    <div class="print_div" v-for="(item3, index3) in list" :key="index3"
                        :style="index3 < list.length - 1 ? 'page-break-after:always;' : ''">
                        <div class="title">报价单</div>
                        <div class="flex_ac" style="margin-top: -50px;">
                            <div class="flex1">日期：{{ item3.sale_quote_date }}</div>
                            <div class="flex_s right_tt">单据编号：{{ item3.sale_quote_no }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">供货单位：{{ item3.supplier }}</div>
                            <div class="flex_s right_tt">业务员：{{ item3.admin_name }}</div>
                        </div>
                        <div class="flex_ac">
                            <div class="flex1">客户单位：{{ item3.merchant.merchant_name }}</div>

                        </div>
                        <table class="print_table">
                            <tbody>
                                <tr>
                                    <td>产品编号</td>
                                    <td width="23%">注册证名 产品名称 规格型号</td>
                                    <td width="100px">生产企业</td>
                                    <td>单位</td>
                                    <td>数量</td>
                                    <td>报价</td>
                                    <td>金额</td>
                                    <!-- <td>
                                        <div>批号</div>
                                        <div>生产日期</div>
                                    </td>
                                    <td>有效期至</td> -->
                                    <td width="100px">注册证号</td>
                                </tr>
                                <tr v-for="(item, index) in item3.sale_quote_product" :key="index">
                                    <td>{{ item.product.product_no }}</td>
                                    <td><!-- {{item.product.register_cert_name}} -->{{ item.product.product_name }}<!-- {{item.product.model}} -->
                                    </td>
                                    <td>{{ item.product.manufacturer }}</td>
                                    <td>{{ item.pack_unit_name }}</td>
                                    <td style="white-space:nowrap;">{{ item.product_num }}</td>
                                    <td style="white-space:nowrap;">{{ cutZero(item.quote_price) }}</td>
                                    <td style="white-space:nowrap;">
                                        {{ cutZero((parseFloat(item.quote_price) * parseFloat(item.product_num) ||
                                            0).toFixed(2)) }}
                                    </td>
                                    <!-- <td>
                                        <div>{{ item.batch_number }}</div>
                                    </td>
                                    <td>{{ item.valid_until || '长效期' }}</td> -->
                                    <td>{{ item.product.register_cert_no }}</td>
                                </tr>
                                <tr class="pad_tb10">
                                    <td>合计：</td>
                                    <td colspan="3" style="text-align: left; padding-left: 5px;">大写
                                        {{ buty_money(item3.amount) }}</td>
                                    <td colspan="3">整单金额合计(小写)</td>
                                    <td colspan="2" style="text-align: left; padding-left: 5px;">
                                        ￥：{{ cutZero(item3.amount) }}元</td>
                                </tr>
                            </tbody>
                        </table>
                        <div style="margin-top: 5px; line-height: 20px;">
                            <div class="flex_ac">
                                <div class="flex1">
                                    <!-- <div class="show_print">{{ item3.address_text || '收件地址：' }}</div>
                                    <div class="hide_print">
                                        收件地址：
                                        <el-select v-model="item3.address" @change="change($event, index3)"
                                            placeholder="请输入收货人搜索" size="small" clearable style="width:300px" filterable>
                                            <el-option
                                                :label="item.title + '-' + item.mobile + '-' + item.province_name + item.city_name + item.district_name + item.street_name + item.detail"
                                                v-for="(item, index) in addresslist" :key="index"
                                                :value="item.id"></el-option>
                                        </el-select>
                                    </div> -->
                                </div>
                                <div class="flex_s" style="margin-left:10px; font-weight: bold;">
                                    <!-- 收到货物后，请核对。如有问题请72小时内反馈。 -->
                                </div>
                            </div>
                            <div>

                            </div>
                            <div>备注：{{ item3.remark }}</div>
                        </div>
                    </div>
                </template>

                <!-- 销售出库单版式三 -->
                <!-- <template v-if="cur_model==6">
                <div class="print_div">
                    
                    <div class="title">销售出库单</div>
                    <div class="flex_ac" style="margin-top: -50px;">
                        <div class="flex1">销售日期：{{data.sale_out_date}}</div>
                        <div class="flex_s right_tt">单据编号：{{data.sale_out_no}}</div>
                    </div>
                    <div class="flex_ac">
                        <div class="flex1">供货单位：{{data.supplier}}</div>
                        <div class="flex_s right_tt">业务员：{{data.admin_name}}</div>
                    </div>
                    <div class="flex_ac">
                        <div class="flex1">收货单位：{{data.sale_plan.merchant.merchant_name}}</div>
                    </div>
                    <table class="print_table">
                        <tbody>
                            <tr> 
                            	<td>产品编号</td>
                                <td>注册证名 产品名称 规格型号</td>
                                <td>生产企业</td>
                                <td>单位</td>
                                <td>数量</td>
                                <td>单价</td>
                                <td>金额</td>
                                <td><div>批号</div><div>生产日期</div></td>
                                <td>有效期至</td>
                                <td>注册证号</td>
                            </tr>
                            <tr v-for="(item,index) in list" :key="index">
                            	<td>{{JSON.parse(item.sale_plan_product.product).product_no}}</td>
                            	<td>{{JSON.parse(item.sale_plan_product.product).register_cert_name}} {{JSON.parse(item.sale_plan_product.product).product_name}} {{JSON.parse(item.sale_plan_product.product).model}}</td>
                            	<td>{{JSON.parse(item.sale_plan_product.product).manufacturer}}</td>
                            	<td>{{item.pack_unit_name}}</td>
                            	<td>{{item.product_num}}</td>
                            	<td>{{item.sale_price}}</td>
                            	<td>
                            	    {{(parseFloat(item.sale_price)*parseFloat(item.product_num) || 0).toFixed(2)}}
                            	</td>
                            	<td><div>{{item.batch_number}}</div></td>
                            	<td>{{item.valid_until || '长效期'}}</td>
                            	<td>{{JSON.parse(item.sale_plan_product.product).register_cert_no}}</td>
                            </tr>
                            <tr class="pad_tb10">
                            	<td>合计：</td>
                                <td colspan="4" style="text-align: left; padding-left: 5px;">大写  {{buty_money(data.amount)}}</td>
                                <td colspan="3">整单金额合计(小写)</td>
                                <td colspan="2" style="text-align: left; padding-left: 5px;">￥：{{data.amount}}元</td>
                            </tr>
                        </tbody>
                    </table>
                    <div style="margin-top: 5px; line-height: 20px;">
                        <div class="flex_ac">
                            <div   class="flex1">
                                <div class="show_print">{{address_text || '收件地址：'}}</div>
                                <div class="hide_print">
                                    收件地址：
                                    <el-select v-model="address" @change="change" placeholder="请输入收货人搜索" size="small" clearable 
                                        style="width:300px" filterable>
                                        <el-option :label="item.title+'-'+item.mobile+'-'+item.province_name+item.city_name+item.district_name+item.street_name+item.detail" v-for="(item,index) in addresslist"
                                            :key="index" :value="item.id"></el-option>
                                    </el-select>
                                </div>
                            </div>
                            <div class="flex_s" style="margin-left:10px; font-weight: bold;">收到货物后，请核对。如有问题请72小时内反馈。</div>
                        </div>
                        <div contenteditable>发货员：{{data.consignor}} <span class="pad_l10">复核员：{{data.reviewer}} </span>  <span class="pad_l10">客户签收：</span></div>
                        <div contenteditable>备注：</div>
                    </div>
                </div>
            </template> -->
            </div>
            <template #footer>
                <span class="dialog-footer  hide_print">
                    <el-button type="primary" @click="confirm">确定打印</el-button>
                    <el-button @click="dialogVisible = false">取消</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name: 'print',
    props: {

    },
    data() {
        return {
            // 列表模板
            loading: false,
            dialogVisible: false,
            // models:{
            //     '1':{name:'采购计划单'}, // 采购计划单
            //     '2':{name:'采购验收单'}, // 采购验收单
            //     '3':{name:'采购入库单'}, // 采购入库单
            //     '4':{name:'销售出库单'}, // 销售出库单
            //     '5':{name:'销售计划单'}, // 销售计划单
            //     '6':{name:'销售退回单'}, // 销售退回单
            //     '7':{name:'采购退货单'}, // 采购退货单
            // },
            // size:[{id:'1',name:'链式打印1/2尺寸'},{id:'2',name:'链式打印2/2尺寸'},{id:'3',name:'标准A4尺寸'}],
            size: [{ id: '1', name: '样式1' }, { id: '2', name: '样式2' }],
            size_id: '1',
            cur_model: '',
            model_list: [],
            data: '',
            today: '',
            list: [],
            addresslist: [],
            address: '',
            address_text: '收件地址：',
            audit: [],
            fontlist: ['微软雅黑', '宋体', '楷体', '隶书', '仿宋'],
            font: ''
        }
    },
    methods: {
        /**
          *去除小数点后多余的0
        */
        cutZero(old) {
            //拷贝一份 返回去掉零的新串
            old = old + "";
            var newstr = old;
            //循环变量 小数部分长度
            var leng = old.length - old.indexOf(".") - 1;
            //判断是否有效数
            if (old.indexOf(".") > -1) {
                //循环小数部分
                for (var i = leng; i > 0; i--) {
                    //如果newstr末尾有0
                    if (newstr.lastIndexOf("0") > -1 && newstr.substr(newstr.length - 1, 1) == 0) {
                        var k = newstr.lastIndexOf("0");
                        //如果小数点后只有一个0 去掉小数点
                        if (newstr.charAt(k - 1) == ".") {
                            return newstr.substring(0, k - 1);
                        } else {
                            //否则 去掉一个0
                            newstr = newstr.substring(0, k);
                        }
                    } else {
                        //如果末尾没有0
                        return newstr;
                    }
                }
            }
            return old;
        },
        get_sh_data(index) {
            let arr = this.list[index].purchase_plan_audit;
            let str = '';
            for (let i = 0; i < arr.length; i++) {
                str += arr[i].position_name + ': ' + arr[i].full_name + ' 审核 ' + (arr[i].opinion == 1 ? '同意' : '不同意') + ' '
            }
            return str
        },
        init(ids, type) {
            console.log(ids);
            this.font = localStorage.font_sele || '';
            this.cur_model = type;
            this.loading = true;
            this.dialogVisible = true;
            this.size_id = '1';
            this.today = this.getMyDate(Date.parse(new Date()), 'year');
            this.remoteMethod();
            // 采购计划单
            if (this.cur_model == 1) {
                this.$httpGet("/backend/PurchasePlan/print", { id: ids.join(',') }).then((res) => {
                    if (res.status == 200) {
                        this.list = res.data
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            }

            // 采购验收单
            if (this.cur_model == 2) {
                this.$httpGet("/backend/PurchaseAcceptance/print", { id: ids.join(',') }).then((res) => {
                    if (res.status == 200) {
                        // this.data=res.data.purchase_plan[0];
                        // this.list=res.data.purchase_acceptance_product;
                        // this.audit=res.data.purchase_plan_audit;
                        // this.data.amount=res.data.amount
                        this.list = res.data
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            }

            // 采购入库单
            if (this.cur_model == 3) {
                this.$httpGet("/backend/PurchaseStorage/print", { id: ids.join(',') }).then((res) => {
                    if (res.status == 200) {
                        // this.data=res.data.purchase_acceptance.purchase_plan;
                        // this.list=res.data.purchase_storage_product;
                        // this.audit=res.data.purchase_plan_audit;

                        this.list = res.data
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            }

            // 销售出库单
            if (this.cur_model == 4) {
                this.$httpGet("/backend/SaleOut/print", { id: ids.join(',') }).then((res) => {
                    if (res.status == 200) {
                        // this.data=res.data;
                        // this.list=res.data.sale_out_product;
                        this.list = res.data
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            }

            // 销售计划单
            if (this.cur_model == 5) {
                this.$httpGet("/backend/SalePlan/print", { id: ids.join(',') }).then((res) => {
                    if (res.status == 200) {
                        // this.data=res.data;
                        // this.list=res.data.sale_plan_product;

                        this.list = res.data
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            }
            // 销售退回单
            if (this.cur_model == 6) {
                this.$httpGet("/backend/SaleReturn/print", { id: ids.join(',') }).then((res) => {
                    if (res.status == 200) {
                        // this.data=res.data;
                        // this.list=res.data.sale_return_product;
                        this.list = res.data
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            }

            // 采购退货单
            if (this.cur_model == 7) {
                this.$httpGet("/backend/PurchaseReturn/print", { id: ids.join(',') }).then((res) => {
                    if (res.status == 200) {
                        // this.data=res.data;
                        // this.list=res.data.purchase_return_product;
                        this.list = res.data
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            }
            // 报价单
            if (this.cur_model == 8) {
                this.$httpGet("/backend/SaleQuote/print", { id: ids.join(',') }).then((res) => {
                    if (res.status == 200) {
                        // this.data=res.data;
                        // this.list=res.data.purchase_return_product;
                        this.list = res.data
                    } else {
                        this.$message.error(res.message);
                    }
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                });
            }
        },
        change(e, index) {
            let n = 0;
            for (let i = 0; i < this.addresslist.length; i++) {
                let item = this.addresslist[i]
                if (item.id == e) {
                    n++
                    this.list[index].address_text = '收件地址：' + item.province_name + item.city_name + item.district_name + item.street_name + item.detail + '; 联系人：' + item.title + '; 电话：' + item.mobile
                }
            }
            if (n == 0) {
                this.list[index].address_text = '收件地址：'
            }
        },
        remoteMethod(keyword) {
            this.$httpGet("/backend/ShippingAddress/index", { size: 1000000, title: keyword }).then((res) => {
                if (res.status == 200) {
                    this.addresslist = res.data.data;
                } else {
                    this.$message.error(res.message);
                }
            }).catch((err) => {
                console.log(err);
            });
        },
        buty_money(money) {
            //汉字的数字
            var cnNums = new Array('零', '壹', '贰', '叁', '肆', '伍', '陆', '柒', '捌', '玖');
            //基本单位
            var cnIntRadice = new Array('', '拾', '佰', '仟');
            //对应整数部分扩展单位
            var cnIntUnits = new Array('', '万', '亿', '兆');
            //对应小数部分单位
            var cnDecUnits = new Array('角', '分', '毫', '厘');
            //整数金额时后面跟的字符
            var cnInteger = '整';
            //整型完以后的单位
            var cnIntLast = '元';
            //最大处理的数字
            var maxNum = 999999999999999.9999;
            //金额整数部分
            var integerNum;
            //金额小数部分
            var decimalNum;
            //输出的中文金额字符串
            var chineseStr = '';
            //分离金额后用的数组，预定义
            var parts;
            if (money == '') { return ''; }
            money = parseFloat(money);
            if (money >= maxNum) {
                //超出最大处理数字
                return '';
            }
            if (money == 0) {
                chineseStr = cnNums[0] + cnIntLast + cnInteger;
                return chineseStr;
            }
            //转换为字符串
            money = money.toString();
            if (money.indexOf('.') == -1) {
                integerNum = money;
                decimalNum = '';
            } else {
                parts = money.split('.');
                integerNum = parts[0];
                decimalNum = parts[1].substr(0, 4);
            }
            //获取整型部分转换
            if (parseInt(integerNum, 10) > 0) {
                var zeroCount = 0;
                var IntLen = integerNum.length;
                for (var i = 0; i < IntLen; i++) {
                    var n = integerNum.substr(i, 1);
                    var p = IntLen - i - 1;
                    var q = p / 4;
                    var m = p % 4;
                    if (n == '0') {
                        zeroCount++;
                    } else {
                        if (zeroCount > 0) {
                            chineseStr += cnNums[0];
                        }
                        //归零
                        zeroCount = 0;
                        chineseStr += cnNums[parseInt(n)] + cnIntRadice[m];
                    }
                    if (m == 0 && zeroCount < 4) {
                        chineseStr += cnIntUnits[q];
                    }
                }
                chineseStr += cnIntLast;
            }
            //小数部分
            if (decimalNum != '') {
                var decLen = decimalNum.length;
                for (var i = 0; i < decLen; i++) {
                    var n = decimalNum.substr(i, 1);
                    if (n != '0') {
                        chineseStr += cnNums[Number(n)] + cnDecUnits[i];
                    }
                }
            }
            if (chineseStr == '') {
                chineseStr += cnNums[0] + cnIntLast + cnInteger;
            } else if (decimalNum == '') {
                chineseStr += cnInteger;
            }
            return chineseStr;
        },
        getMyDate(str, _type) {
            var oDate = new Date(str),
                oYear = oDate.getFullYear(),
                oMonth = oDate.getMonth() + 1,
                oDay = oDate.getDate(),
                oHour = oDate.getHours(),
                oMin = oDate.getMinutes(),
                oSen = oDate.getSeconds();
            //  格式 13:12
            if (_type == 'time_h_m') {
                var oTime = getzf(oHour) + ':' + getzf(oMin)
            }
            if (_type == 'time_h_ms') {

                var _times = getzf(oMin).toString()[0] + '0'
                var oTime = getzf(oHour) + ':' + _times
            }
            // 格式 13:12:25
            if (_type == 'time_h_m_s') {
                var oTime = getzf(oHour) + ':' + getzf(oMin) + ':' + getzf(oSen);
            }
            // 格式 2017-12-12
            if (_type == 'year') {
                var oTime = oYear + '' + getzf(oMonth) + '' + getzf(oDay)
            }
            // 格式 2017-12
            if (_type == 'year_m') {
                var oTime = oYear + '-' + getzf(oMonth)
            }
            // 格式 2017-12-12 13:12:25
            if (_type == 'year_time') {
                var oTime = oYear + '-' + getzf(oMonth) + '-' + getzf(oDay) + ' ' + getzf(oHour) + ':' + getzf(oMin) + ':' + getzf(oSen);//最后拼接时间  
            }
            // 需要更多格式可再自定义
            return oTime;

            //补0操作
            function getzf(num) {
                if (parseInt(num) < 10) {
                    num = '0' + num;
                }
                return num;
            }
        },
        confirm() {
            // if(this.cur_model==1 || this.cur_model==4 || this.cur_model==5 || this.cur_model==6){
            //     if(this.address_text==''){
            //         this.$message.error('请选择地址');
            //         return false;
            //     }
            // }
            localStorage.font_sele = this.font
            window.print()
        },

    }
}
</script>

<style scoped>.dialog_box {
    display: flex;
    justify-content: space-between;
}

.print_table .pad_tb10 td {
    padding: 10px 3px;
    font-weight: bold;
}

.print_div {
    padding: 10px 20px;
    margin: 20px auto;
    max-height: 550px;
    overflow: auto;
    border: 1px solid #ddd;
    font-size: 12px;
    color: #000;
    line-height: 24px;
}

.print_div .title {
    font-size: 32px;
    line-height: 1;
    text-align: center;
    padding-bottom: 20px;
}

.print_div .right_tt {
    min-width: 240px;
}

.print_table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #000;
    margin-top: 10px;
}

.print_table tr td {
    line-height: 1.2;
    font-size: 12px;
    border: 1px solid #000;
    padding: 3px 3px;
    text-align: center;
}

.pad_l10 {
    padding-left: 10px;
}

.show_print {
    display: none;
}

.font_div * {
    font-family: inherit;
}</style>
